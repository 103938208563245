(function () {
	'use strict';

	angular
		.module('app')
		.factory('salesforce', [
			'salesforceConfig',
			'salesforceShared',
			'seedcodeCalendar',
			'calendarIO',
			'daybackIO',
			'manageSettings',
			'utilities',
			salesforce,
		]);

	function salesforce(
		salesforceConfig,
		salesforceShared,
		seedcodeCalendar,
		calendarIO,
		daybackIO,
		manageSettings,
		utilities
	) {
		var sharedMethods = salesforceShared.getShared(
			'fbk',
			fbk,
			createConnection
		);

		var eventEditQueue = {};

		return {
			getConfig: getConfig,
			getFieldMap: getFieldMap,
			getUnusedMap: getUnusedMap,
			getAllowHTMLMap: getAllowHTMLMap,
			getHiddenFieldMap: getHiddenFieldMap,
			getReadOnlyFieldMap: getReadOnlyFieldMap,
			getAllowTextFieldMap: getAllowTextFieldMap,
			getEventEditQueue: getEventEditQueue,
			getObjectInfo: getObjectInfo,
			getResourceObjects: getResourceObjects,
			getSchedules: getSchedules,
			changeScheduleName: changeScheduleName,
			changeScheduleColor: changeScheduleColor,
			disableSchedule: disableSchedule,
			getUnscheduled: getUnscheduled,
			getMapEvents: getMapEvents,
			getEvents: getEvents,
			editEvent: editEvent,
			deleteEvent: deleteEvent,
			showDetail: showDetail,
			getContacts: getContacts,
			getProjects: getProjects,
			getResources: getResources,
			getFieldData: getFieldData,
			getPicklist: getPicklist,
			getRepeatingRule: getRepeatingRule,
			deleteSourceEvent: deleteSourceEvent,
			repeatingConfig: repeatingConfig,
			calculateEndDateTime: calculateEndDateTime,
			clearRecurringFields: clearRecurringFields,
			deleteRepeatingInstance: deleteRepeatingInstance,
			updateRepeatingEventUntil: updateRepeatingEventUntil,
		};

		function getConfig() {
			return salesforceConfig.config();
		}

		function getFieldMap() {
			return salesforceConfig.fieldMap();
		}

		function getUnusedMap() {
			return salesforceConfig.unusedMap();
		}

		function getAllowHTMLMap() {
			return salesforceConfig.allowHTMLMap();
		}

		function getHiddenFieldMap() {
			return salesforceConfig.hiddenFieldMap();
		}

		function getReadOnlyFieldMap() {
			return salesforceConfig.readOnlyFieldMap();
		}

		function getAllowTextFieldMap() {
			return salesforceConfig.allowTextFieldMap();
		}

		function getEventEditQueue() {
			return eventEditQueue;
		}

		function getObjectInfo(objectName, callback) {
			fbk.objectInfo(objectName, runCallback);
			function runCallback(data) {
				if (callback) {
					callback(data && data.payload ? data.payload : null);
				}
			}
		}

		function getResourceObjects(resourceID, source, callback) {
			fbk.resourceObjects(resourceID, source, callback);
		}

		function createConnection(schedule) {
			var user = daybackIO.getUser();

			var connection = {
				objectName: schedule.objectName,
				fieldMap: schedule.fieldMap,
			};
			return connection;
		}

		function updateRepeatingEventUntil(event, newUntil, callback) {
			sharedMethods.updateRepeatingEventUntil(event, newUntil, callback);
		}

		function deleteRepeatingInstance(event, callback) {
			deleteEvent(event, callback, event.schedule);
		}

		function repeatingConfig() {
			return sharedMethods.repeatingConfig();
		}

		function clearRecurringFields(editObject) {
			sharedMethods.clearRecurringFields(editObject);
		}

		function calculateEndDateTime(request, ruleObject) {
			return sharedMethods.calculateEndDateTime(request, ruleObject);
		}

		function getRepeatingRule(request, ruleObject) {
			return sharedMethods.getRepeatingRule(request, ruleObject);
		}

		function deleteSourceEvent(event, callback) {
			sharedMethods.deleteSourceEvent(event, callback, onError);

			function onError(result) {
				var message;
				if (result[0].errorCode.indexOf('INVALID_SESSION_ID') !== -1) {
					message =
						'There was an error saving the event and your changes will be reverted: ' +
						cleanError(result[0].message);
					utilities.showModal(
						'Operation Failed',
						message,
						'Re-Authorize',
						sessionError,
						'Return To Event',
						confirmError
					);
				} else {
					message =
						'There was an error deleting the item: ' +
						cleanError(result[0].message);
					utilities.showModal(
						'Operation Failed',
						message,
						null,
						null,
						'Return To Event',
						confirmError
					);
				}

				function confirmError() {
					return;
				}
			}
		}

		function getPicklist(object, field, schedule, callback) {
			sharedMethods.getPickList(object, field, schedule, callback);
		}

		function getFieldData(objectName, callback) {
			sharedMethods.getFieldData(objectName, callback);
		}

		function getSchedules(callback, sourceTemplate) {
			var config = seedcodeCalendar.get('config');
			var sources = seedcodeCalendar.get('sources');
			var schedule;
			var schedules = [];
			for (var i = 0; i < sources.length; i++) {
				if (sources[i].sourceTypeID === sourceTemplate.id) {
					schedule = setSchedule(sources[i]);
					schedules.push(schedule);
				}
			}

			fbk.saveSchedules(schedules);

			callback(schedules, sourceTemplate);
			//Grab the external settings file and run the apply settings function

			function setSchedule(schedule) {
				//Clean schedule data
				schedule.sourceID = schedule.id;

				// Set source specific custom field properties
				if (schedule.customFields) {
					for (var property in schedule.customFields) {
						schedule.customFields[property].mutateInput =
							sharedMethods.customFieldMutateInput;
					}
				}

				calendarIO.cleanSchedule(schedule, sourceTemplate);

				return schedule;
			}
		}

		function changeScheduleName(name, callback, schedule) {
			manageSettings.setCalendarName(name, schedule, callback);
			return name;
		}

		function changeScheduleColor(color, callback, schedule) {
			manageSettings.setBackgroundColor(color, schedule, callback);
			return color;
		}

		function disableSchedule(scheduleID) {
			sharedMethods.disableSchedule(scheduleID);
		}

		function getUnscheduled(callback, schedule) {
			sharedMethods.getUnscheduled(callback, schedule);
		}

		function getMapEvents(bounds, callback, schedule) {
			sharedMethods.getMapEvents(bounds, callback, schedule, onError);

			function onError(result) {
				var message =
					'Salesforce Error: ' +
					cleanError(result[0].errorCode) +
					' Object Name: ' +
					schedule.name;
				utilities.showMessage(message, 0, 8000, 'error', null, true);
			}
		}

		function getEvents(
			start,
			end,
			timezone,
			callback,
			schedule,
			options,
			eventID,
			fetchID,
			requestOverride
		) {
			sharedMethods.getEvents(
				start,
				end,
				timezone,
				callback,
				schedule,
				false,
				onError,
				requestOverride
			);

			function onError(result) {
				var message;
				if (result[0].errorCode.indexOf('INVALID_SESSION_ID') !== -1) {
					message =
						'There was an error trying to contact Salesforce: ' +
						cleanError(result[0].message);
					utilities.showModal(
						'Operation Failed',
						message,
						'Cancel',
						confirmError,
						'Re-Authorize',
						sessionError
					);
				} else {
					message =
						'Salesforce Error: ' +
						cleanError(result[0].errorCode) +
						' Object Name: ' +
						schedule.name;
					utilities.showMessage(
						message,
						0,
						8000,
						'error',
						null,
						true
					);
				}
			}

			function confirmError() {
				return;
			}

			function sessionError(e) {
				var sandbox;
				var uri;
				if (!Sfdc.canvas.oauth.loggedin()) {
					sandbox = api.isSandbox();
					if (sandbox) {
						uri =
							'https://test.salesforce.com/services/oauth2/authorize';
					} else {
						uri = Sfdc.canvas.oauth.loginUrl();
					}
					Sfdc.canvas.oauth.login({
						uri: uri,
						params: {
							response_type: 'token',
							client_id:
								'3MVG9sG9Z3Q1Rlbee3KicFdnnEKWHDa2Cg7S1VhpoITTE_GArMaRyoEGjPQ03jkAQBudjj7Easc6Ms3wjvXM4',
							redirect_uri: encodeURIComponent(
								'https://app.dayback.com/salesforce/callback.html'
							),
						},
					});
				}
				return false;
			}
		}

		//Edit Events
		function editEvent(
			event,
			revertObject,
			revertFunc,
			changes,
			editID,
			callback,
			schedule
		) {
			sharedMethods.editEvent(
				event,
				revertObject,
				revertFunc,
				changes,
				editID,
				callback,
				schedule
			);
		}

		//Delete Event

		function deleteEvent(event, callback, schedule) {
			sharedMethods.deleteEvent(event, callback, schedule);
		}

		//utility func for making errors titlecase

		function cleanError(str) {
			return str.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
				return (
					txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
				);
			});
		}

		// open URL / Navigate

		function showDetail(id, editEvent) {
			fbk.publish('dbk.navigate', {
				id: id[0],
				url: '/' + id[0],
				new: true,
			});
		}

		// get contacts by object and criteria for contact selector

		function getContacts(
			callback,
			object,
			searchField,
			displayField,
			criteria,
			schedule
		) {
			sharedMethods.getContacts(
				callback,
				object,
				searchField,
				displayField,
				criteria,
				schedule
			);
		}

		function getProjects(
			callback,
			object,
			searchField,
			displayField,
			criteria,
			schedule
		) {
			sharedMethods.getProjects(
				callback,
				object,
				searchField,
				displayField,
				criteria,
				schedule
			);
		}

		function getResources(
			callback,
			object,
			searchField,
			displayField,
			criteria,
			schedule
		) {
			sharedMethods.getResources(
				callback,
				object,
				searchField,
				displayField,
				criteria,
				schedule
			);
		}

		function sessionError(e) {
			var sandbox;
			var uri;
			if (!Sfdc.canvas.oauth.loggedin()) {
				sandbox = fbk.isSandbox();
				if (sandbox) {
					uri =
						'https://test.salesforce.com/services/oauth2/authorize';
				} else {
					uri =
						'https://login.salesforce.com/services/oauth2/authorize';
				}
				Sfdc.canvas.oauth.login({
					uri: uri,
					params: {
						response_type: 'token',
						client_id:
							'3MVG9sG9Z3Q1Rlbee3KicFdnnEKWHDa2Cg7S1VhpoITTE_GArMaRyoEGjPQ03jkAQBudjj7Easc6Ms3wjvXM4',
						redirect_uri: encodeURIComponent(
							'https://app.dayback.com/salesforce/callback.html'
						),
					},
				});
			}
			return false;
		}
	}
})();
